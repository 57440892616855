import { useEffect, useState } from "react";
import { getPageHead, getVendorTheme } from "../service/theme-service";
import Config from "../config.json";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import InnerHTML from "dangerously-set-html-content";
import LoadingScreen from "./layout/loading-screen";

export function VendorThemeProvider({ children }) {
  const vendor = window.location.pathname.split("/")[1];
  const appTheme = Config.themes[Config.activeTheme];
  const [pageHead, setPageHead] = useState("");
  const [loading, setLoading] = useState(true);

  if (vendor) {
    Config.vendor = vendor;
  }

  // Exports all the CSS variables from config
  const [pageStyle, setPageStyle] = useState({
    "--theme-color-primary": appTheme.colorPrimary,
    "--theme-color-secondary": appTheme.colorSecondary,

    "--theme-page-text-color": appTheme.pageTextColor,
    "--theme-header-text-color": appTheme.headerTextColor,
    "--theme-footer-text-color": appTheme.footerTextColor,

    "--theme-page-background-color": appTheme.pageBackground,

    "--theme-single-page-color": appTheme.singlePageBackgroundColor,
    "--theme-single-page-text-color": appTheme.singlePageTextColor,
    "--theme-single-page-accent-color": appTheme.singlePageAccentColor,

    "--theme-box-color": appTheme.boxColor,
    "--theme-box-hr-color": appTheme.boxHrColor,

    "--theme-header-color": appTheme.headerColor,
    "--theme-top-header-color": appTheme.topHeaderColor,
    "--theme-footer-color": appTheme.footerColor,
    "--theme-bottom-footer-color": appTheme.bottomFooterColor,

    "--theme-header-footer-hr-color": appTheme.headerFooterHrColor,
    "--theme-page-hr-color": appTheme.pageHrColor,

    "--theme-header-logo-desktop-width": appTheme.headerLogoDesktopWidth,
    "--theme-header-logo-mobile-width": appTheme.headerLogoMobileWidth,
    "--theme-footer-logo-desktop-width": appTheme.footerLogoDesktopWidth,
    "--theme-footer-logo-mobile-width": appTheme.footerLogoMobileWidth,
    "--theme-payment-top-logo-desktop-width":
      appTheme.paymentTopLogoDesktopWidth,
    "--theme-payment-top-logo-mobile-width": appTheme.paymentTopLogoMobileWidth,

    "--theme-product-title-mobile-color": appTheme.productTitleMobileColor,

    "--theme-product-expiration-color": appTheme.productExpirationColor,
    "--theme-product-expiration-expiring-color":
      appTheme.productExpirationExpiringColor,
    "--theme-product-expiration-contrast-color":
      appTheme.productExpirationContrastColor,
    "--theme-product-expiration-expiring-contrast-color":
      appTheme.productExpirationExpiringContrastColor,

    "--theme-footer-mobile-headers-color": appTheme.footerMobileHeadersColor,

    "--theme-box-title-color": appTheme.boxTitleColor,

    "--theme-slide-menu-color": appTheme.slidesMenuColor,
    "--theme-menu-highlight-color": appTheme.menuHighlightColor,
    "--theme-font-family": appTheme.fontFamily,
  });

  let defaultButtonsProperty = {
    variant: "contained",
  };
  if (appTheme.showContainedButtonsBorder) {
    defaultButtonsProperty = {
      ...defaultButtonsProperty,
      sx: { border: 1 },
    };
  }
  const desktopMuiTheme = createTheme({
    palette: {
      primary: {
        main: appTheme.primaryButtonColor,
        contrastText: appTheme.primaryButtonTextColor,
      },
      loader: {
        main: appTheme.colorPrimary,
      },
      heartCheckbox: {
        main: appTheme.favoriteButtonColor,
        contrastText: "#FFFFFF",
      },
      productFavoriteButton: {
        main: "#FFFFFF",
        contrastText: appTheme.favoriteButtonColor,
      },
      productFavoriteButtonUnselected: {
        main: "#6B7684",
      },
      slides: {
        main: "#ffffff",
        contrastText: appTheme.colorPrimary,
      },
      checkbox: {
        main: appTheme.colorPrimary,
      },
      checkboxSinglePage: {
        main: appTheme.singlePageAccentColor,
        contrastText: appTheme.singlePageTextColor,
      },
      checkboxSinglePageMobileOnly: {
        main: appTheme.colorPrimary,
        contrastText: appTheme.pageTextColor,
      },
      button: {
        main: appTheme.primaryButtonColor,
        contrastText: appTheme.primaryButtonTextColor,
      },
      buttonLoginDesktop: {
        main: appTheme.primaryButtonLoginDesktopColor,
        contrastText: appTheme.primaryButtonLoginDesktopTextColor,
      },
      textInput: {
        main: appTheme.colorSecondary,
      },
      textInputMobileAccent: {
        main: appTheme.colorPrimary,
      },
      radio: {
        main: appTheme.colorSecondary,
      },
      listingPageIcon: {
        main: appTheme.listingPageIconsColor,
      },
      listingPageChip: {
        main: appTheme.listingPageChipsColor,
        contrastText: appTheme.pageTextColor,
      },
    },
    shape: {
      borderRadius: 0,
    },
    text: {
      primary: appTheme.pageTextColor,
      secondary: appTheme.colorPrimary,
    },
    components: {
      MuiButton: {
        defaultProps: defaultButtonsProperty,
      },
    },
  });

  const mobileMuiTheme = createTheme({
    palette: {
      primary: {
        main: appTheme.singlePageAccentColor,
      },
      loader: {
        main: appTheme.colorPrimary,
      },
      heartCheckbox: {
        main: appTheme.favoriteButtonColor,
        contrastText: "#FFFFFF",
      },
      productFavoriteButton: {
        main: appTheme.favoriteButtonColor,
      },
      productFavoriteButtonUnselected: {
        main: "#6B7684",
      },
      slides: {
        main: "#ffffff",
        contrastText: appTheme.colorPrimary,
      },
      checkbox: {
        main: appTheme.colorPrimary,
      },
      checkboxSinglePage: {
        main: appTheme.singlePageAccentColor,
        contrastText: appTheme.singlePageTextColor,
      },
      checkboxSinglePageMobileOnly: {
        main: appTheme.singlePageAccentColor,
        contrastText: appTheme.singlePageTextColor,
      },
      button: {
        main: appTheme.singlePageButtonsColor,
        contrastText: appTheme.singlePageButtonsTextColor,
      },
      buttonLoginDesktop: {
        main: appTheme.singlePageButtonsColor,
        contrastText: appTheme.singlePageButtonsTextColor,
      },
      textInput: {
        main: appTheme.colorSecondary,
      },
      textInputMobileAccent: {
        main: appTheme.singlePageAccentColor,
      },
      radio: {
        main: appTheme.colorSecondary,
      },
      listingPageIcon: {
        main: appTheme.listingPageIconsColor,
      },
      listingPageChip: {
        main: appTheme.listingPageChipsColor,
        contrastText: appTheme.pageTextColor,
      },
    },
    text: {
      primary: appTheme.pageTextColor,
      secondary: appTheme.colorPrimary,
    },
    shape: {
      borderRadius: 0,
    },
  });

  useEffect(() => {
    let isSubscribed = true;

    const getPageHeadPromise = getPageHead().then((head) => {
      if (isSubscribed) {
        setPageHead(`<script>${head}</script>`);
      }
    });

    const promises = [getPageHeadPromise];

    if (vendor) {
      const getVendorThemePromise = getVendorTheme(vendor).then(
        ({ titoli, accenti, testo, logo, result }) => {
          const activeTheme = Config.themes[Config.activeTheme];
          if (result === false) {
            window.location.replace(Config.flashwineUrl);
          }
          if (logo) {
            activeTheme.headerLogo = logo;
          }
          if (testo) {
            activeTheme.singlePageButtonsColor = testo;
            activeTheme.primaryButtonLoginDesktopColor = testo;
            activeTheme.primaryButtonColor = testo;
            activeTheme.colorSecondary = testo;
            activeTheme.singlePageAccentColor = testo;
          }
          if (accenti) {
            activeTheme.favoriteButtonColor = accenti;
            activeTheme.colorPrimary = accenti;
          }
          if (titoli) {
            activeTheme.listingPageIconsColor = titoli;
          }
          if (isSubscribed) {
            setPageStyle((pageStyle) => {
              return {
                ...pageStyle,
                "--theme-color-primary": accenti,
                "--theme-single-page-accent-color": accenti,
                "--theme-header-text-color": titoli,
                "--theme-box-title-color": titoli,
                "--theme-slide-menu-color": titoli,
                "--theme-color-secondary": testo,
              };
            });
          }
        }
      );
      promises.push(getVendorThemePromise);
    }

    Promise.all(promises).then(() => {
      if (isSubscribed) {
        setLoading(false);
      }
    });
    return () => (isSubscribed = false);
  }, [vendor]);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Redirects to flashwine if the theme is bicerin but no vendor is provided
  if (Config.activeTheme === "vendor" && !vendor) {
    window.location.replace(Config.flashwineUrl);
  }

  if (loading) {
    return (
      <div style={pageStyle} className="min-vh-100">
        <ThemeProvider theme={isMobile ? mobileMuiTheme : desktopMuiTheme}>
          <div className="container-fluid min-vh-100">
            <LoadingScreen className="min-vh-100" />
          </div>
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div style={pageStyle} className="min-vh-100">
      <ThemeProvider theme={isMobile ? mobileMuiTheme : desktopMuiTheme}>
        {children}
      </ThemeProvider>
      <InnerHTML html={pageHead} />
    </div>
  );
}
