import { useState } from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DynamicLink from "./dynamic-link";
import { useMediaQuery } from "react-responsive";

export default function HamburgerMenu({ menu }) {
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const closeFullScreenMenu = () => {
    document.body.classList.remove("no-scroll");
    document.documentElement.classList.remove("no-scroll");
    if (hamburgerMenuOpen) {
      setHamburgerMenuOpen(false);
    }
  };

  // Handles the menu opening and closing
  let hamburgerMenuClass =
    "overlay-menu theme-single-page d-md-none " +
    (hamburgerMenuOpen ? "fit-content d-block" : "d-none");

  const onClickHamburgerButton = () => {
    setHamburgerMenuOpen(true);
    document.body.classList.add("no-scroll");
    document.documentElement.classList.add("no-scroll");
  };

  // Removes the no-scroll in case of screen resize
  if (!isMobile) {
    closeFullScreenMenu();
  }

  const menuHtml = menu.map((m, i) => (
    <DynamicLink className="text-decoration-none" {...m} key={i}>
      <p
        onClick={closeFullScreenMenu}
        className="m-0 py-2 px-3 text-left hover-gray account-menu-label theme-single-page-text"
      >
        {m.title}
      </p>
    </DynamicLink>
  ));
  return (
    <>
      <MenuOutlinedIcon
        className="cursor-pointer mr-1 mr-md-2 d-md-none"
        onClick={onClickHamburgerButton}
      />

      <div className={hamburgerMenuClass}>
        <div className="row d-block d-md-none">
          <div className="col text-right p-3">
            <CloseIcon
              fontSize="large"
              onClick={closeFullScreenMenu}
              className="theme-single-page-text"
            />
          </div>
        </div>
        {menuHtml}
      </div>
    </>
  );
}
