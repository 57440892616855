import { useState } from "react";
import { Link } from "react-router-dom";
import { path as landingPath } from "../../../pages/landing-page";
import { path as cartPath } from "../../../pages/cart-page";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import TopHeader from "../../layout/top-header";
import CarouselMenu from "../../layout/carousel-menu";
import Config from "../../../config.json";
import AccountMenu from "../../layout/account-menu";
import SearchBar from "../../layout/search-bar";
import HamburgerMenu from "../../layout/hamburger-menu";
import { useMediaQuery } from "react-responsive";

export default function HeaderFlashwine(props) {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const openSearchBar = () => setSearchBarOpen(true);

  const headerLogoUrl = Config.themes[Config.activeTheme].headerLogo;
  const headerLogo = headerLogoUrl.startsWith("/")
    ? process.env.PUBLIC_URL + headerLogoUrl
    : headerLogoUrl;

  return (
    <>
      <div
        className={
          "theme-header theme-header-text row " + (isMobile ? "sticky-top" : "")
        }
      >
        <div className="col">
          {searchBarOpen ? (
            <>
              <TopHeader className="invisible" />
              <SearchBar setSearchBarOpen={setSearchBarOpen} />
            </>
          ) : (
            <>
              <TopHeader />
              <div className="row">
                <div className="col layout-max-width">
                  <div className="row align-items-center justify-content-center header">
                    <div className="col-3 col-md-2 text-md-left text-center">
                      <HamburgerMenu {...props} />
                      <SearchOutlinedIcon
                        className="cursor-pointer fixed-size-icon"
                        onClick={openSearchBar}
                      />
                    </div>
                    <div className="col-6 col-md-6 text-center">
                      <Link to={landingPath}>
                        <img
                          className="theme-header-logo"
                          src={headerLogo}
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <div className="col-3 col-md-2 text-md-right text-center">
                      <>
                        <AccountMenu {...props} />
                        <span className="notify-container ml-2">
                          {props.cartItems > 0 && (
                            <span className="notify-bubble">
                              {props.cartItems}
                            </span>
                          )}
                          <Link to={cartPath}>
                            <ShoppingBagOutlinedIcon className="theme-header-text" />
                          </Link>
                        </span>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <hr className="col" />
          </div>
        </div>
      </div>
      <CarouselMenu {...props} />
    </>
  );
}
