export async function getOrders() {
  return await fetch("/wc/v3/orders");
}

export async function createOrder(
  billing,
  shipping,
  line_items,
  shipping_lines
) {
  return await fetch("/wc/v3/orders", {
    method: "POST",
    body: JSON.stringify({
      payment_method: "bacs",
      payment_method_title: "Carta di credito",
      billing,
      shipping,
      line_items,
      shipping_lines,
    }),
  });
}

export async function getStripeIntentId(orderId, amount) {
  return await fetch(
    `/apticashop/v1/stripe-payment?order_id=${orderId}&amount=${amount}`
  );
}

export async function getStripePublicKey() {
  return await fetch(`/apticashop/v1/stripe-key`);
}
