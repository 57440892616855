import { useState } from "react";
import { Link } from "react-router-dom";
import { path as landingPath } from "../../../pages/landing-page";
import { path as cartPath } from "../../../pages/cart-page";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import TopHeader from "../../layout/top-header";
import Config from "../../../config.json";
import AccountMenu from "../../layout/account-menu";
import SearchBar from "../../layout/search-bar";
import DynamicLink from "../../layout/dynamic-link";
import HamburgerMenu from "../../layout/hamburger-menu";
import { useMediaQuery } from "react-responsive";

export default function HeaderFinesip(props) {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const menuHtml = props.menu?.map((m, i) => (
    <DynamicLink
      className="p-2 theme-header-text hover-accent text-uppercase font-16 fw-500"
      {...m}
      key={i}
    >
      {m.title}
    </DynamicLink>
  ));

  const openSearchBar = () => setSearchBarOpen(true);

  return (
    <div
      className={
        "theme-header theme-header-text row " + (isMobile ? "sticky-top" : "")
      }
    >
      <div className="col">
        {searchBarOpen ? (
          <SearchBar setSearchBarOpen={setSearchBarOpen} />
        ) : (
          <>
            <TopHeader />
            <div className="row">
              <div className="col layout-max-width">
                <div className="row align-items-center justify-content-center header">
                  <div className="col-3 text-md-left text-center d-block d-md-none">
                    <HamburgerMenu {...props} />
                    <SearchOutlinedIcon
                      className="cursor-pointer fixed-size-icon"
                      onClick={openSearchBar}
                    />
                  </div>
                  <div className="col-6 col-md-2 text-md-left text-center">
                    <Link to={landingPath}>
                      <img
                        className="theme-header-logo"
                        src={
                          process.env.PUBLIC_URL +
                          Config.themes[Config.activeTheme].logo
                        }
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="col-6 d-none d-md-block">{menuHtml}</div>
                  <div className="col-3 col-md-2 text-right">
                    <SearchOutlinedIcon
                      className="cursor-pointer mr-md-2 mr-1 d-none d-md-inline-block"
                      onClick={openSearchBar}
                    />
                    <AccountMenu {...props} />
                    <span className="notify-container ml-md-2 ml-1">
                      {props.cartItems > 0 && (
                        <span className="notify-bubble">{props.cartItems}</span>
                      )}
                      <Link to={cartPath}>
                        <ShoppingBagOutlinedIcon className="theme-header-text" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <hr className="col" />
        </div>
      </div>
    </div>
  );
}
