import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import DynamicLink from "./dynamic-link";

export default function CollapsibleMenu({ title, entries }) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => setCollapsed(!collapsed);

  const menuEntries = entries.map((m, i) => (
    <DynamicLink
      className="font-14 theme-footer-text font-14 fw-300 d-block"
      {...m}
      key={i}
    >
      {m.title}
    </DynamicLink>
  ));

  return (
    <div className="col-12">
      <div
        className="row align-items-center justify-content-between"
        onClick={toggleCollapsed}
      >
        <h5 className="col text-left theme-footer-mobile-header font-16 mb-0">
          {title}
        </h5>
        <div className="col font-16 theme-footer-mobile-header text-right">
          {collapsed ? (
            <KeyboardArrowRightIcon className="cursor-pointer" />
          ) : (
            <KeyboardArrowDownIcon className="cursor-pointer" />
          )}
        </div>
      </div>
      <div className="pb-3">{!collapsed && menuEntries}</div>
    </div>
  );
}
