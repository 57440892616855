import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { path as accountPath } from "../../pages/account-page";
import { path as ordersPath } from "../../pages/orders-page";
import { path as addressPath } from "../../pages/address-page";
import { path as wishlistPath } from "../../pages/wishlist-page";
import { path as landingPath } from "../../pages/landing-page";
import { path as vendorPath } from "../../pages/vendor-page";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { logout } from "../../service/user-service";
import CloseIcon from "@mui/icons-material/Close";
import Config from "../../config.json";
import { isLoggedIn } from "../../service/user-service";
import { path as loginPath } from "../../pages/login-page";

export default function AccountMenu({ isVendor }) {
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [mouseOverAccountButton, setMouseOverAccountButton] = useState(false);
  const [mouseOverAccountMenu, setMouseOverAccountMenu] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Handles menu overlay and search bar switches
  const menuClosingDelay = 300;
  const onMouseOverAccountMenu = () => setMouseOverAccountMenu(true);
  const onMouseLeaveAccountMenu = () =>
    setTimeout(() => setMouseOverAccountMenu(false), menuClosingDelay);
  const onMouseOverAccountButton = () => {
    if (isLoggedIn()) setMouseOverAccountButton(true);
  };
  const onMouseLeaveAccountButton = () =>
    setTimeout(() => setMouseOverAccountButton(false), menuClosingDelay);

  const closeFullScreenMenu = (navigateTo) => {
    document.body.classList.remove("no-scroll");
    document.documentElement.classList.remove("no-scroll");
    if (accountMenuOpen) {
      setAccountMenuOpen(false);
    }

    if (navigateTo) {
      if (
        Config.redirectAccountMenuToFlashwine &&
        Config.activeTheme !== "main"
      ) {
        window.location.replace(Config.flashwineUrl + "#" + navigateTo);
      } else {
        if (navigateTo.startsWith("/")) {
          history.push(navigateTo);
        } else {
          window.location.href = navigateTo;
        }
      }
    }
  };

  // Removes the no-scroll in case of screen resize
  if (!isMobile) {
    closeFullScreenMenu();
  }

  // Handles the menu opening and closing
  let accountMenuClass = "overlay-menu theme-single-page";
  if (!isMobile) {
    if (mouseOverAccountButton || mouseOverAccountMenu) {
      accountMenuClass += " fit-content d-block";
    } else {
      accountMenuClass += " d-none";
    }
  } else {
    if (accountMenuOpen) {
      accountMenuClass += " fit-content d-block";
    } else {
      accountMenuClass += " d-none";
    }
  }

  const onLogoutClick = () => {
    logout();
    closeFullScreenMenu();

    // Navigates to landing if not already there
    if (landingPath === location.pathname) {
      history.go(0);
    } else {
      history.push(landingPath);
    }
  };

  const onClickAccountButton = () => {
    if (!isLoggedIn()) {
      history.push(loginPath);
    } else if (isMobile) {
      setAccountMenuOpen(true);
      document.body.classList.add("no-scroll");
      document.documentElement.classList.add("no-scroll");
    }
  };

  const isVendorUpperCase = isVendor?.toUpperCase();
  const hasShop =
    isVendorUpperCase === "MANAGER" || isVendorUpperCase === "ADMIN";
  const isVendorPending = isVendorUpperCase === "PENDING";

  return (
    <>
      <span
        className="cursor-pointer"
        onMouseOver={onMouseOverAccountButton}
        onMouseLeave={onMouseLeaveAccountButton}
        onClick={onClickAccountButton}
      >
        <AccountCircleOutlinedIcon />
      </span>

      <div
        className={accountMenuClass}
        onMouseOver={onMouseOverAccountMenu}
        onMouseLeave={onMouseLeaveAccountMenu}
      >
        <div className="row d-block d-md-none">
          <div className="col text-right p-3">
            <CloseIcon
              fontSize="large"
              onClick={() => closeFullScreenMenu()}
              className="theme-single-page-text"
            />
          </div>
        </div>

        <p
          onClick={() => closeFullScreenMenu(ordersPath)}
          className="cursor-pointer m-0 py-2 px-3 text-left hover-gray account-menu-label theme-single-page-text-mobile-only"
        >
          ORDINI
        </p>
        <p
          onClick={() => closeFullScreenMenu(accountPath)}
          className="cursor-pointer m-0 py-2 px-3 text-left hover-gray account-menu-label theme-single-page-text-mobile-only"
        >
          ACCOUNT
        </p>
        <p
          onClick={() => closeFullScreenMenu(addressPath)}
          className="cursor-pointer m-0 py-2 px-3 text-left hover-gray account-menu-label theme-single-page-text-mobile-only"
        >
          INDIRIZZI
        </p>
        <p
          onClick={() => closeFullScreenMenu(wishlistPath)}
          className="cursor-pointer m-0 py-2 px-3 text-left hover-gray account-menu-label theme-single-page-text-mobile-only"
        >
          WISHLIST
        </p>

        <p
          onClick={() => closeFullScreenMenu("/pages/content/customer-care")}
          className="cursor-pointer m-0 py-2 px-3 text-left hover-gray account-menu-label theme-single-page-text-mobile-only"
        >
          CUSTOMER CARE
        </p>
        <p
          onClick={() =>
            closeFullScreenMenu(hasShop ? Config.wpAdminUrl : vendorPath)
          }
          className={
            "cursor-pointer m-0 py-2 px-3 text-left hover-gray account-menu-label theme-single-page-text-mobile-only " +
            (isVendorPending ? "d-none" : "")
          }
        >
          {hasShop ? "GESTISCI SHOP" : "APRI IL TUO SHOP"}
        </p>
        <div
          className="m-0 py-2 px-2 text-left hover-gray account-menu-label cursor-pointer"
          onClick={onLogoutClick}
        >
          <LogoutOutlinedIcon
            fontSize="small"
            className="theme-single-page-text-accent"
          />
          <p
            id="logout-icon"
            className="d-inline pl-2 theme-single-page-text-mobile-only account-menu-label"
          >
            LOGOUT
          </p>
        </div>
      </div>
    </>
  );
}
