import { Link } from "react-router-dom";

// This component allows to redirect dynamically to either internal or external app links according to the url
export default function DynamicLink({ url, className, children }) {
  if (url?.startsWith("/")) {
    return (
      <Link className={className} to={url}>
        {children}
      </Link>
    );
  }

  return (
    <a className={className} href={url}>
      {children}
    </a>
  );
}
