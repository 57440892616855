import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LandingPage, { path as landingPath } from "./pages/landing-page";
import Config from "./config.json";
import fetchIntercept from "fetch-intercept";
import ProtectedRoute from "./components/protected-route";
import PaymentLayout, {
  path as paymentPath,
} from "./pages/payment/payment-layout";
import PageLayout, { path as pagesPath } from "./pages/page-layout";
import { VendorThemeProvider } from "./components/vendor-theme-provider";
import { path as notFoundPath } from "./pages/notfound-page";
import { createBrowserHistory } from "history";
import { logout } from "./service/user-service";

fetchIntercept.register({
  request: function (url, config) {
    // Default header values if not specified
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (config) {
      headers = { ...headers, ...config.headers };
    }

    const token = localStorage.getItem("FW-TOKEN");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    // Prepends the base URL for all non-static resources
    if (!url.endsWith(".js")) {
      url = Config.apiBaseUrl + url;
      console.debug("Remapped fetch URL to " + url);
    }
    return [url, { ...config, headers }];
  },
  response: async function (response) {
    if (response.status === 403 || response.status === 401) {
      console.debug("Session expired. Logging out");
      logout();
      createBrowserHistory().push("/");
      window.location.reload();
    }
    return await response.json();
  },
  responseError: function (error) {
    console.error(error);
    return Promise.reject(error);
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <VendorThemeProvider>
        <Switch>
          <ProtectedRoute
            path={paymentPath}
            component={PaymentLayout}
            authenticated={true}
          />
          <ProtectedRoute
            path={pagesPath}
            component={PageLayout}
            authenticated={false}
          />
          <ProtectedRoute
            path={landingPath}
            component={LandingPage}
            authenticated={false}
            exact
          />
          <Route>
            <Redirect to={notFoundPath} />
          </Route>
        </Switch>
      </VendorThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
