import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export default function SearchBar({ setSearchBarOpen }) {
  const [search, setSearch] = useState("");
  const closeSearchBar = () => setSearchBarOpen(false);

  const history = useHistory();

  const onSearchSubmit = (event) => {
    event.preventDefault();
    closeSearchBar();

    history.push(`/pages/listing?search=${search}`);
  };

  return (
    <form
      className="row align-items-center justify-content-center header"
      onSubmit={onSearchSubmit}
    >
      <div className="col layout-max-width">
        <div className="row justify-content-center align-items-center">
          <div className="col-2 col-md-1 text-center text-md-left">
            <SearchOutlinedIcon className="fixed-size-icon" />
          </div>
          <div className="col-8 col-md-8 text-left">
            <input
              type="text"
              placeholder="Cerca offerte"
              className="border-0 w-100 searchbar theme-header theme-header-text fw-300"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <input type="submit" hidden />
          </div>
          <div className="col-2 col-md-1 text-right">
            <CloseIcon
              className="cursor-pointer fixed-size-icon"
              onClick={closeSearchBar}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
