import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { stringToPrice } from "../service/formatting-service";

export default function CartRow({
  item_key,
  name,
  price,
  quantity,
  shipping,
  featured_image,
  onRowDelete,
  onQuantityUpdate,
}) {
  const onDeleteRowClicked = () => onRowDelete(item_key);
  const onQuantityChange = (e) => onQuantityUpdate(item_key, e.target.value);

  const formattedPrice = stringToPrice(price);
  const formattedShippingPrice = shipping ? stringToPrice(shipping) : "Inclusa";

  const selectOptions = [...Array(5).keys()]
    .map((i) => i + Math.max(1, quantity.value - 2))
    .map((i) => (
      <MenuItem value={i} key={i}>
        {i}
      </MenuItem>
    ));

  return (
    <div className="row mb-4">
      <div className="col-3 col-md-2">
        <img src={featured_image} alt={name} className="mw-100 mh-100" />
      </div>
      <div className="col-9">
        <div className="row theme-page-text align-items-center justify-content-start">
          <div className="col-md-8 col-12 text-left font-14-16">{name}</div>
          <div className="col-md-1 col-6 order-2 order-md-1 text-md-center text-left">
            <Select
              value={quantity.value}
              onChange={onQuantityChange}
              variant="standard"
              className="z-1"
              disableUnderline
            >
              {selectOptions}
            </Select>
          </div>
          <div className="col-md-3 col-6 order-1 order-md-2 text-md-right text-left font-16">
            <strong className="align-middle">{formattedPrice}</strong>
          </div>
        </div>
        <div className="row mt-2 align-items-center">
          <div className="col-10 text-left font-12">
            <span className="text-muted align-middle">Spedizione:</span>{" "}
            <span className="theme-page-text align-middle">
              {formattedShippingPrice}
            </span>
          </div>
          <div className="col-2 text-md-right text-center">
            <DeleteForeverOutlinedIcon
              onClick={onDeleteRowClicked}
              className="theme-color-secondary cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
