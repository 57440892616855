import SectionTitle from "../../components/layout/section-title";
import PaymentBox from "../../components/payment-box";
import { useState, useEffect } from "react";
import { getCart } from "../../service/cart-service";
import LoadingScreen from "../../components/layout/loading-screen";
import { formatPageTitle } from "../../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/payment/payment3";
export default function Payment3Page({ setBarPercentageLength }) {
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    getCart().then((cart) => {
      if (isSubscribed) {
        setCart(cart);
        setLoading(false);
      }
    });
    return () => (isSubscribed = false);
  }, []);

  useEffect(() => setBarPercentageLength(100), [setBarPercentageLength]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="row justify-content-center my-md-4 mt-4 align-items-end align-items-md-start flex-fill">
      <Helmet>
        <title>{formatPageTitle("Ordine confermato")}</title>
      </Helmet>
      <div className="col layout-max-width">
        <SectionTitle
          title="Ordine confermato"
          subtitle="Grazie per aver acquistato su flashwine! Il tuo ordine sarà preso in carico al più presto"
        />
        <div className="row justify-content-center">
          <PaymentBox
            title="Riepilogo Ordine"
            className="col-12 col-md-6 p-3"
            cart={cart}
          />
        </div>
      </div>
    </div>
  );
}
