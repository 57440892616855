import Config from "../../config.json";

export default function SectionTitle({ title, subtitle }) {
  const fineSipHeader = (
    <div className="col">
      <h5 className="font-18-24 fw-600 mb-0 text-uppercase theme-color-primary theme-font-family">
        {title}
      </h5>
    </div>
  );

  const flashwineHeader = (
    <div className="col">
      <h1 className="font-18-24 theme-header-text fw-600 mb-0 text-uppercase">
        {title}
      </h1>
      <div className="primary-rectangle m-auto"></div>
    </div>
  );

  const header =
    Config.activeTheme === "additional" ? fineSipHeader : flashwineHeader;

  return (
    <div className="row justify-content-center">
      <div className="col">
        <div className="row justify-content-center text-center">{header}</div>
        <div className="row justify-content-center pt-2 mb-4 text-center">
          <p className="m-0 theme-page-text section-subtitle col-md-10 col-12">
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
}
