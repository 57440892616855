import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import Button from "@mui/material/Button";
import AddressForm from "../components/address-form";
import LoadingScreen from "../components/layout/loading-screen";
import {
  getCustomerInfo,
  saveCustomerInfo,
  getUserId,
} from "../service/user-service";
import { cleanAddress, formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/pages/address";
export default function AddressPage({ setCloseableOnMobile }) {
  const [loading, setLoading] = useState(true);
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onShowBillingAddressClicked = () => setShowBillingAddress(true);

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    let isSubscribed = true;
    getCustomerInfo(getUserId()).then(({ billing, shipping }) => {
      if (isSubscribed) {
        cleanAddress(billing);
        cleanAddress(shipping);

        setBillingAddress(billing);
        setShippingAddress(shipping);

        // If the billing address is set, show the form
        if (billing.address_1) {
          setShowBillingAddress(true);
        }

        setLoading(false);
      }
    });
    return () => (isSubscribed = false);
  }, []);

  const onAddressFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const response = await saveCustomerInfo(getUserId(), {
      billing: billingAddress,
      shipping: shippingAddress,
    });

    if (response.message) {
      setErrorMessage(response.message);
    } else {
      setErrorMessage(response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Indirizzi")}</title>
      </Helmet>
      <SectionTitle title="Indirizzi" subtitle="Gestisci i tuoi indirizzi" />
      <form onSubmit={onAddressFormSubmit}>
        <AddressForm
          address={shippingAddress}
          setAddress={setShippingAddress}
        />
        <div className="row justify-content-center text-left mb-4 mt-3">
          <sub className="col-10 col-md-6 p-0">
            Modifica o aggiungi{" "}
            <u
              className="theme-color-primary cursor-pointer"
              onClick={onShowBillingAddressClicked}
            >
              l'indirizzo di fatturazione
            </u>
          </sub>
        </div>
        {showBillingAddress && (
          <AddressForm
            address={billingAddress}
            setAddress={setBillingAddress}
          />
        )}

        {errorMessage && (
          <div className="row justify-content-center my-4">
            <div
              className="col-12 col-md-10 text-center text-danger"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          </div>
        )}

        <div className="row justify-content-center my-4">
          <Button
            type="submit"
            className="col-10 col-md-6"
            variant="contained"
            color="button"
          >
            Salva
          </Button>
        </div>
      </form>
    </>
  );
}
