import { useState, useEffect } from "react";
import SectionTitle from "../../components/layout/section-title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { path as paymentTwoPath } from "./payment2-page";
import { path as cartPath } from "../cart-page";
import LoadingScreen from "../../components/layout/loading-screen";
import {
  cleanAddress,
  formatPageTitle,
} from "../../service/formatting-service";
import {
  getCustomerInfo,
  getUserId,
  getUserProfile,
} from "../../service/user-service";
import AddressForm from "../../components/address-form";
import { createOrder } from "../../service/orders-service";
import { getCart } from "../../service/cart-service";
import { Helmet } from "react-helmet";

export const path = "/payment/payment1";
export default function Payment1Page({ setBarPercentageLength }) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [cart, setCart] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;

    Promise.all([
      getCustomerInfo(getUserId()),
      getUserProfile(),
      getCart(),
    ]).then(
      ([{ billing, shipping }, { first_name, last_name }, cartResult]) => {
        if (isSubscribed) {
          cleanAddress(billing);
          cleanAddress(shipping);

          setBillingAddress(billing);
          setShippingAddress(shipping);

          // If the billing address is set, show the form
          if (billing.address_1) {
            setShowBillingAddress(true);
          }

          setName(first_name);
          setSurname(last_name);
          setCart(cartResult);
          setLoading(false);

          if (!cartResult || cartResult.length < 1) {
            history.replace(cartPath);
          }
        }
      }
    );
    return () => (isSubscribed = false);
  }, [history]);

  useEffect(() => setBarPercentageLength(33), [setBarPercentageLength]);

  if (loading) {
    return <LoadingScreen />;
  }

  const onOrderSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const orderItems = cart.items.map(({ id, quantity }) => {
      return { product_id: id, quantity: quantity.value };
    });
    const { id, total, message } = await createOrder(
      billingAddress,
      shippingAddress,
      orderItems,
      []
    );
    setLoading(false);
    if (message) {
      setErrorMessage(message);
    } else {
      history.push({ pathname: paymentTwoPath, state: { id, total } });
    }
  };

  return (
    <div className="row justify-content-center my-md-4 mt-4 flex-fill">
      <form className="col layout-max-width" onSubmit={onOrderSubmit}>
        <Helmet>
          <title>{formatPageTitle("Pagamento e spedizione")}</title>
        </Helmet>
        <SectionTitle
          title="Pagamento e spedizione"
          subtitle="Ricorda che la gestione delle spedizioni è totalmente a carico dei venditori"
        />
        <div className="row justify-content-center">
          <TextField
            label="Nome"
            variant="standard"
            className="col-md-6 col-10"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <div className="row justify-content-center mt-3">
          <TextField
            label="Cognome"
            variant="standard"
            className="col-md-6 col-10"
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
            color="textInput"
          />
        </div>
        <AddressForm
          address={shippingAddress}
          setAddress={setShippingAddress}
        />

        <div className="row justify-content-center my-3">
          <p className="col-md-6 col-10 text-left p-0 theme-page-text font-12-14">
            Indirizzo di{" "}
            <u
              className="cursor-pointer"
              onClick={() => setShowBillingAddress(true)}
            >
              fatturazione
            </u>{" "}
            diverso da quello di spedizione?
          </p>
        </div>
        {showBillingAddress && (
          <AddressForm
            address={billingAddress}
            setAddress={setBillingAddress}
          />
        )}

        {errorMessage && (
          <div className="row justify-content-center my-4">
            <div
              className="col-12 col-md-10 text-center text-danger"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          </div>
        )}

        <div className="row justify-content-center my-4">
          <div className="col-10 col-md-6 p-0">
            <Button
              className="w-100"
              type="submit"
              variant="contained"
              color="button"
            >
              Procedi
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
