import jwt_decode from "jwt-decode";

const tokenKey = "FW-TOKEN";
const wishlistKey = "FW-WISHLIST-ID";
export async function login(username, password) {
  const response = await fetch("/jwt-auth/v1/token", {
    method: "POST",
    body: JSON.stringify({ username, password }),
  });
  if (response && response.access_token) {
    localStorage.setItem(tokenKey, response.access_token);
    localStorage.setItem(wishlistKey, response.wishlist_code);
  }
  return response;
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(wishlistKey);
}

export function getUserToken() {
  return localStorage.getItem(tokenKey);
}

export function getUserWishlistId() {
  return localStorage.getItem(wishlistKey);
}

export function isLoggedIn() {
  return getUserToken() !== null;
}

export async function registerUser(
  email,
  password,
  name,
  surname,
  telephone,
  privacy,
  marketing,
  newsletter
) {
  return await fetch("/wp/v2/users", {
    method: "POST",
    body: JSON.stringify({
      username: email,
      email,
      password,
      name: name + " " + surname,
      first_name: name,
      last_name: surname,
      marketing,
      privacy,
      telephone,
      newsletter,
    }),
  });
}

export async function getUserProfile() {
  return await fetch("/wp/v2/users/me");
}

export async function updateUser(
  email,
  password,
  name,
  surname,
  telephone,
  newsletter,
  marketing
) {
  const body = {
    username: email,
    email,
    name: name + " " + surname,
    first_name: name,
    last_name: surname,
    telephone,
    newsletter,
    marketing,
  };
  if (password) {
    body.password = password;
  }
  return await fetch("/wp/v2/users/me", {
    method: "POST",
    body: JSON.stringify(body),
  });
}

export async function getCustomerInfo(id) {
  return await fetch("/wc/v3/customers/" + id);
}

export async function saveCustomerInfo(id, request) {
  return await fetch("/wc/v3/customers/" + id, {
    method: "PUT",
    body: JSON.stringify(request),
  });
}

export function getUserId() {
  return jwt_decode(getUserToken()).data.user.id;
}
