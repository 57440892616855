import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ProductCard from "./product-card";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";

export default function ProductCarousel({ title, items }) {
  const [pointer, setPointer] = useState(0);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const wineCards = items.map((w, i) => (
    <ProductCard
      {...w}
      key={i}
      className="left-right-animation"
      style={{
        left: (isMobile ? -50 : -100 / 3) * pointer + "%",
      }}
    />
  ));

  const pointerIncreasePossible = () => {
    const elementsInRow = isMobile ? 2 : 3;
    return pointer + elementsInRow < items.length;
  };
  const pointerDecreasePossible = () => {
    return pointer > 0;
  };
  const increasePointer = () => {
    if (pointerIncreasePossible()) {
      setPointer(pointer + 1);
    }
  };
  const decreasePointer = () => {
    if (pointerDecreasePossible()) {
      setPointer(pointer - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: increasePointer,
    onSwipedRight: decreasePointer,
  });

  const decreasePointerClassName =
    "mr-2 " +
    (pointerDecreasePossible()
      ? "theme-header-text cursor-pointer"
      : "text-gray-4");
  const increasePointerClassName =
    "ml-2 " +
    (pointerIncreasePossible()
      ? "theme-header-text cursor-pointer"
      : "text-gray-4");

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-6 col-8">
          <div className="primary-rectangle mb-2"></div>
          <p className="theme-header-text fw-300 font-16">{title}</p>
        </div>
        <div className="col-md-6 col-4 text-right">
          <KeyboardArrowLeftIcon
            className={decreasePointerClassName}
            onClick={decreasePointer}
          />
          <KeyboardArrowRightIcon
            className={increasePointerClassName}
            onClick={increasePointer}
          />
        </div>
      </div>
      <div className="row overflow-hidden flex-nowrap" {...swipeHandlers}>
        {wineCards}
      </div>
    </>
  );
}
