import {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import SectionTitle from "../../components/layout/section-title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { path as paymentThreePath } from "./payment3-page";
import { path as paymentOnePath } from "./payment1-page";
import { path as orderAbortedPath } from "../order-aborted-page";
import LoadingScreen from "../../components/layout/loading-screen";
import { InputAdornment } from "@mui/material";
import card from "../../assets/card.png";
import { getStripeIntentId } from "../../service/orders-service";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { clearCart } from "../../service/cart-service";
import { formatPageTitle } from "../../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/payment/payment2";
export default function Payment2Page({ setBarPercentageLength, location }) {
  const [loading, setLoading] = useState(true);
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    let isSubscribed = true;
    if (!location.state) {
      // Returns to the first payment page if no state is passed (e.g. a page refresh)
      history.replace(paymentOnePath);
    } else {
      setBarPercentageLength(66);
      const { id, total } = location.state;
      getStripeIntentId(id, total).then(({ payment_intent_id }) => {
        if (isSubscribed) {
          setPaymentIntentId(payment_intent_id);
          setLoading(false);
        }
      });
    }
    return () => (isSubscribed = false);
  }, [setBarPercentageLength, location.state, history]);

  if (loading) {
    return <LoadingScreen />;
  }

  const onPaymentFormSubmit = async (event) => {
    event.preventDefault();
    const cardHolderName = event.target[0].value;

    // Can't hide the form with loading since it would interfere with the iframe data retrieval from stripe.
    const cardNumberElement = elements.getElement("cardNumber");

    const paymentResult = await stripe.confirmCardPayment(paymentIntentId, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: cardHolderName,
        },
      },
    });

    if (paymentResult.error) {
      history.push({
        pathname: orderAbortedPath,
        state: { errorMessage: paymentResult.error.message },
      });
    } else {
      clearCart();
      history.push(paymentThreePath);
    }
  };

  const StripeInput = ({ component: Component, inputRef, ...props }) => {
    const elementRef = useRef();
    useImperativeHandle(inputRef, () => ({
      focus: () => elementRef.current.focus,
    }));
    const options = { placeholder: "" };
    return (
      <Component
        onReady={(element) => (elementRef.current = element)}
        {...props}
        options={options}
      />
    );
  };

  const WrappedStripeInput = forwardRef((props, ref) => (
    <StripeInput inputRef={ref} {...props} />
  ));

  return (
    <div className="row justify-content-center my-md-4 mt-4 flex-fill">
      <Helmet>
        <title>{formatPageTitle("Pagamento e spedizione")}</title>
      </Helmet>
      <form onSubmit={onPaymentFormSubmit} className="col layout-max-width">
        <SectionTitle
          title="Pagamento e spedizione"
          subtitle="Ricorda che la gestione delle spedizioni è totalmente a carico dei venditori"
        />

        <div className="row justify-content-center">
          <TextField
            label="Titolare carta"
            variant="standard"
            className="col-md-6 col-10"
            type="text"
            required
            color="textInput"
          />
        </div>
        <div className="row justify-content-center mt-3">
          <TextField
            label="Numero carta"
            variant="standard"
            className="col-md-6 col-10"
            type="number"
            InputLabelProps={{ shrink: true }}
            color="textInput"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={card} alt="card" />
                </InputAdornment>
              ),
              inputComponent: WrappedStripeInput,
              inputProps: {
                component: CardNumberElement,
              },
            }}
          />
        </div>
        <div className="row justify-content-center mt-3">
          <TextField
            label="MM/AA"
            variant="standard"
            className="col-md-3 pr-3 col-5"
            type="text"
            InputLabelProps={{ shrink: true }}
            required
            InputProps={{
              inputComponent: WrappedStripeInput,
              inputProps: {
                component: CardExpiryElement,
              },
            }}
            color="textInput"
          />
          <TextField
            label="CVC"
            variant="standard"
            className="col-md-3 col-5"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: WrappedStripeInput,
              inputProps: {
                component: CardCvcElement,
              },
            }}
            required
            color="textInput"
          />
        </div>

        <div className="row justify-content-center my-5">
          <div className="col-10 col-md-6 p-0">
            <Button
              className="w-100"
              type="submit"
              variant="contained"
              color="button"
            >
              Conferma
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
