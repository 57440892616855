import { CircularProgress } from "@mui/material";

export default function LoadingScreen({ className }) {
  return (
    <div className={"row flex-fill align-items-center " + (className ?? "")}>
      <div className="col text-center">
        <CircularProgress className="m-5" color="loader" />
      </div>
    </div>
  );
}
