import ProductCard from "../../product-card";

export default function ExpiringOffersSection({ expiringOffers }) {
  const wineCards = expiringOffers?.map((w, i) => (
    <ProductCard {...w} key={i} />
  ));

  return (
    <div className="row justify-content-center my-5">
      <div className="col layout-max-width">
        <div className="row justify-content-center">
          <h4 className="col text-center fw-700 font-26-30 theme-page-text mb-5 theme-font-family">
            Proposte in scadenza
          </h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10 col-12">
            <div className="row">{wineCards}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
