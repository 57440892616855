import SectionTitle from "../components/layout/section-title";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPageContent } from "../service/page-service";
import { useState } from "react";
import LoadingScreen from "../components/layout/loading-screen";
import { useHistory } from "react-router-dom";
import { path as notFoundPath } from "../pages/notfound-page";
import { Helmet } from "react-helmet";
import { formatPageTitle } from "../service/formatting-service";

export const path = "/pages/content/:slug";
export default function ContentPage({ setCloseableOnMobile }) {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({});

  const { slug } = useParams();

  const history = useHistory();

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);
    getPageContent(slug).then((response) => {
      if (isSubscribed) {
        setLoading(false);
        if (response.length > 0) {
          setContent(response[0]);
        } else {
          history.replace(notFoundPath);
        }
      }
    });
    return () => (isSubscribed = false);
  }, [history, slug]);

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(content?.title?.rendered)}</title>
      </Helmet>
      <SectionTitle title={content?.title?.rendered} />
      <div className="row justify-content-center">
        <div
          className="col col-md-10"
          dangerouslySetInnerHTML={{ __html: content?.content?.rendered }}
        ></div>
      </div>
    </>
  );
}
