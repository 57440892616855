import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import CartRow from "../components/cart-row";
import {
  getCart,
  removeProductFromCart,
  updateItemQuantity,
} from "../service/cart-service";
import LoadingScreen from "../components/layout/loading-screen";
import PaymentBox from "../components/payment-box";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/pages/cart";
export default function CartPage({ setCloseableOnMobile, setCartItems }) {
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    let isSubscribed = true;
    getCart().then((cart) => {
      if (isSubscribed) {
        setCart(cart);
        setLoading(false);
      }
    });
    return () => (isSubscribed = false);
  }, []);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const onRowDelete = async (id) => {
    setLoading(true);
    await removeProductFromCart(id);
    const cart = await getCart();
    setCartItems(cart.item_count);
    setCart(cart);
    setLoading(false);
  };

  const onQuantityUpdate = async (id, newQuantity) => {
    setLoading(true);
    const cart = await updateItemQuantity(id, newQuantity);
    setCartItems(cart.item_count);
    setCart(cart);
    setLoading(false);
  };

  const cartRows = cart?.items?.map((o, i) => (
    <CartRow
      {...o}
      key={i}
      onRowDelete={onRowDelete}
      onQuantityUpdate={onQuantityUpdate}
    />
  ));
  const articlesNumber = cart?.item_count ?? 0;

  //const articlesTotal = cart.reduce((prev, curr) => prev.price + curr.price, 0);

  const title = articlesNumber + " Articoli nel carrello";

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Carrello")}</title>
      </Helmet>
      <SectionTitle
        title={title}
        subtitle="Ricorda che la gestione delle spedizioni è totalmente a carico dei
          venditori"
      />
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">{cartRows}</div>
        <div className="col-12 col-md-4 px-0">
          <PaymentBox
            cart={cart}
            title="Metodo di Pagamento"
            className="p-3"
            showPayment
          />
        </div>
      </div>
    </>
  );
}
