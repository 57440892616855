import ProductCarousel from "../../product-carousel";
import Config from "../../../config.json";

export default function HighlightsSection({
  lastOffers,
  expiringOffers,
  exclusiveOffers,
}) {
  return (
    <div className="row my-5">
      <div className="col layout-max-width">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <ProductCarousel title="ULTIME OFFERTE" items={lastOffers} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <ProductCarousel
              title={
                Config.activeTheme === "main"
                  ? "OFFERTE IN SCADENZA"
                  : "OFFERTE BOTTIGLIE SPECIALI"
              }
              items={expiringOffers}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <ProductCarousel
              title={
                Config.activeTheme === "main"
                  ? "ESCLUSIVA FLASHWINE"
                  : "LAST MINUTE BOX"
              }
              items={exclusiveOffers}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
