import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../service/user-service";
import { path as loginPath } from "../pages/login-page";

export default function ProtectedRoute({
  component: Component,
  authenticated,
  ...rest
}) {
  const render = (props) =>
    authenticated && !isLoggedIn() ? (
      <Redirect to={loginPath} />
    ) : (
      <Component {...props} {...rest} />
    );

  return <Route {...rest} render={render} />;
}
