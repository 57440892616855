import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import OrderRow from "../components/order-row";
import { getOrders } from "../service/orders-service";
import LoadingScreen from "../components/layout/loading-screen";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";

export const path = "/pages/orders";
export default function OrdersPage({ setCloseableOnMobile }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    let isSubscribed = true;

    getOrders().then((orders) => {
      if (isSubscribed) {
        setOrders(orders);
        setLoading(false);
      }
    });

    return () => (isSubscribed = false);
  }, []);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const orderRows = orders.map((o, i) => (
    <OrderRow {...o} even={i % 2 === 0} key={i} />
  ));

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Ordini")}</title>
      </Helmet>
      <SectionTitle title="Ordini" subtitle="Storico ordini effettuati" />
      <div className="row justify-content-center">
        <div className="col-md-3 col-4">
          <h5 className="text-left font-12-14 fw-400 text-gray-3">ORDINE</h5>
        </div>
        <div className="col-4">
          <h5 className="text-center font-12-14 fw-400 text-gray-3">DATA</h5>
        </div>
        <div className="col-md-3 col-4">
          <h5 className="text-right font-12-14 fw-400 text-gray-3">TOTALE</h5>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col col-md-10">{orderRows}</div>
      </div>
    </>
  );
}
