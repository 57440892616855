import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { buildExpirationMessage } from "../../../service/formatting-service";

export default function NewsSection({ products }) {
  const productsHtml = products.map((p, i) => {
    const [expirationMessage, expirationClassName] = buildExpirationMessage(
      p.date
    );
    return (
      <div className="col-6 col-md-4 theme-page-text mb-3">
        <div className="row">
          <div className="col">
            <div className="row justify-content-center w-100 mx-0">
              <img
                className="wine-img-card theme-page-text mw-100 mh-100"
                src={p.featured_image}
                alt={p.name}
              />
            </div>
            <div className="font-16 theme-page-text fw-600">{p.name}</div>
            <div>
              <div>
                <strong className="theme-page-text font-14-16 fw-300">
                  {p.subtitle}
                </strong>
              </div>
            </div>
            {expirationMessage && (
              <div className={"font-12-14 fw-400" + expirationClassName}>
                {expirationMessage}
              </div>
            )}
          </div>
          <div className="col">
            <Link to={p.url}>
              <Button variant="outlined">SCOPRI</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="row justify-content-center my-5">
      <div className="col layout-max-width">
        <div className="row justify-content-center">
          <div className="col-md-10 col-12">
            <div className="row">{productsHtml}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
