import Footer from "../components/layout/footer";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, Switch } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { getFooterMenu, getCarouselMenu } from "../service/menu-service";
import LoadingScreen from "../components/layout/loading-screen";
import HeaderFlashwine from "../components/landing/flashwine/header-flashwine";
import HeaderFinesip from "../components/landing/finesip/header-finesip";
import Config from "../config.json";
import ProtectedRoute from "../components/protected-route";
import ProductPage, { path as productPath } from "../pages/product-page";
import SignUpPage, { path as signUpPath } from "../pages/signup-page";
import CartPage, { path as cartPath } from "../pages/cart-page";
import OrdersPage, { path as ordersPath } from "../pages/orders-page";
import LoginPage, { path as loginPath } from "../pages/login-page";
import ListingPage, { path as listingPath } from "../pages/listing-page";
import AccountPage, { path as accountPath } from "../pages/account-page";
import AddressPage, { path as addressPath } from "../pages/address-page";
import WishlistPage, { path as wishlistPath } from "../pages/wishlist-page";
import VendorPage, { path as vendorPath } from "../pages/vendor-page";
import { countCartItems } from "../service/cart-service";
import NotFoundPage, { path as notFoundPath } from "../pages/notfound-page";
import { Route, Redirect } from "react-router-dom";
import OrderAbortedPage, {
  path as orderAbortedPath,
} from "./order-aborted-page";
import ContentPage, { path as contentPath } from "./content-page";
import { formatPageTitle } from "../service/formatting-service";
import { Helmet } from "react-helmet";
import { getUserProfile } from "../service/user-service";

export const path = "/pages";
export default function PageLayout() {
  const history = useHistory();
  const [carouselMenu, setCarouselMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [closeableOnMobile, setCloseableOnMobile] = useState(false);
  const [cartItems, setCartItems] = useState(0);
  const [userProfile, setUserProfile] = useState({});

  const closePage = () => {
    history.goBack();
  };
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    let isSubscribed = true;

    Promise.all([
      getCarouselMenu(),
      getFooterMenu(),
      countCartItems(),
      getUserProfile(),
    ]).then(
      ([
        carouselMenuResult,
        footerMenuResult,
        cartItemsResult,
        userProfileResult,
      ]) => {
        if (isSubscribed) {
          setCarouselMenu(carouselMenuResult);
          setFooterMenu(footerMenuResult);
          setCartItems(cartItemsResult);
          setUserProfile(userProfileResult);
          setLoading(false);
        }
      }
    );

    return () => (isSubscribed = false);
  }, []);

  if (loading) {
    return (
      <div className="container-fluid min-vh-100">
        <LoadingScreen className="min-vh-100" />
      </div>
    );
  }

  const header =
    Config.activeTheme === "additional" ? (
      <HeaderFinesip
        menu={carouselMenu}
        cartItems={cartItems}
        isVendor={userProfile.is_vendor}
      />
    ) : (
      <HeaderFlashwine
        menu={carouselMenu}
        cartItems={cartItems}
        isVendor={userProfile.is_vendor}
      />
    );

  return (
    <div
      className={
        "min-vh-100 d-flex flex-column " +
        (closeableOnMobile && isMobile
          ? "theme-single-page"
          : "theme-page-background")
      }
    >
      <Helmet>
        <title>{formatPageTitle()}</title>
      </Helmet>
      <div className="content container-fluid d-flex flex-column">
        {closeableOnMobile && isMobile ? (
          <div className="row align-items-center justify-content-end d-md-none">
            <CloseIcon
              className="cursor-pointer mt-3 mr-4 theme-single-page-text"
              onClick={closePage}
              fontSize="large"
            />
          </div>
        ) : (
          header
        )}
        <div className="pb-md-3 pt-3 row flex-fill">
          <div className="col layout-max-width">
            <Switch>
              <ProtectedRoute
                path={productPath}
                component={ProductPage}
                setCloseableOnMobile={setCloseableOnMobile}
                setCartItems={setCartItems}
                authenticated={false}
              />
              <ProtectedRoute
                path={signUpPath}
                component={SignUpPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={false}
              />
              <ProtectedRoute
                path={cartPath}
                component={CartPage}
                setCloseableOnMobile={setCloseableOnMobile}
                setCartItems={setCartItems}
                authenticated={true}
              />
              <ProtectedRoute
                path={ordersPath}
                component={OrdersPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={true}
              />
              <ProtectedRoute
                path={loginPath}
                component={LoginPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={false}
              />
              <ProtectedRoute
                path={listingPath}
                component={ListingPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={false}
              />
              <ProtectedRoute
                path={vendorPath}
                component={VendorPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={false}
              />
              <ProtectedRoute
                path={accountPath}
                component={AccountPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={true}
              />
              <ProtectedRoute
                path={addressPath}
                component={AddressPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={true}
              />
              <ProtectedRoute
                path={wishlistPath}
                component={WishlistPage}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={true}
              />
              <ProtectedRoute
                component={NotFoundPage}
                path={notFoundPath}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={false}
              />
              <ProtectedRoute
                component={OrderAbortedPage}
                path={orderAbortedPath}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={true}
              />
              <ProtectedRoute
                component={ContentPage}
                path={contentPath}
                setCloseableOnMobile={setCloseableOnMobile}
                authenticated={false}
              />
              <Route>
                <Redirect to={notFoundPath} />
              </Route>
            </Switch>
          </div>
        </div>
        {(closeableOnMobile && isMobile) || <Footer menu={footerMenu} />}
      </div>
    </div>
  );
}
