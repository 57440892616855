import { path as wishlistPath } from "../../pages/wishlist-page";
import Config from "../../config.json";
import DynamicLink from "./dynamic-link";

export default function TopHeader({ className }) {
  const redirectableUrl = (url) =>
    Config.redirectAccountMenuToFlashwine && Config.activeTheme !== "main"
      ? Config.flashwineUrl + "#" + url
      : url;

  return (
    <div
      className={
        "d-none d-md-block row justify-content-center " + className ?? ""
      }
    >
      <div className="theme-top-header col">
        <div className="row top-header align-items-center">
          <div className="col layout-max-width">
            <div className="row align-items-center justify-content-center font-12 fw-300">
              <div className="col-5">
                {/* <Link
                  className="theme-header-text "
                  to="/pages/content/newsletter"
                >
                  NEWSLETTER
                </Link> */}
              </div>
              <div className="text-right col-5">
                <DynamicLink
                  className="theme-header-text"
                  url={redirectableUrl("/pages/content/customer-care")}
                >
                  CUSTOMER CARE
                </DynamicLink>
                &nbsp; &nbsp; &nbsp;
                <DynamicLink
                  className="theme-header-text"
                  url={redirectableUrl(wishlistPath)}
                >
                  WISHLIST
                </DynamicLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="theme-header-footer-hr" />
    </div>
  );
}
