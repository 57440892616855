export async function addToWishlist(wishlistCode, product_id) {
  return await fetch(`/wc/v3/wishlist/${wishlistCode}/add_product`, {
    method: "POST",
    body: JSON.stringify({ product_id }),
  });
}

export async function getWishlist(wishlistCode) {
  return await fetch(`/wc/v3/wishlist/${wishlistCode}/get_products`);
}

export async function removeFromWishlist(wishlistCode, productId) {
  return await fetch(`/wc/v3/wishlist/remove_product/${productId}`);
}
