import { useState } from "react";
import { stringToPrice } from "../service/formatting-service";

export default function OrderRow({
  id,
  date_created,
  total,
  line_items,
  even,
}) {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => setCollapsed(!collapsed);

  const productDetails = line_items.map((p, i) => (
    <div key={i}>
      <div className="row pt-3">
        <p className="col m-0 text-gray-2 fw-400 font-12-14">{p.name}</p>
      </div>
      <div className="row">
        <div className="col">
          <p className="text-left m-0 text-gray-2 fw-300 font-12-14">
            Quantità: {p.quantity}
          </p>
        </div>
        <div className="col">
          <p className="text-right m-0 text-gray-2 fw-600 font-12-14">
            {stringToPrice(p.subtotal)}
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <p className="col m-0 text-gray-2 fw-300 font-12-14">
          Spedizione: {p.shipping ?? "Inclusa"}
        </p>
      </div>
    </div>
  ));

  const formatDate = (date) =>
    new Date(date).toLocaleString("it-IT", {
      dateStyle: "short",
    });

  return (
    <div className={"py-3 row" + (even ? " theme-box" : "")}>
      <div className="col">
        <div className="row" onClick={toggleCollapse}>
          <div className="col-4 text-left font-16 fw-400 text-gray-2">
            #{id}
          </div>
          <div className="col-4 text-center font-16 fw-400 text-gray-2">
            {formatDate(date_created)}
          </div>
          <div className="col-4 text-right font-16 fw-600 text-gray-2">
            {stringToPrice(total)}
          </div>
        </div>
        <div className="row">
          <div className="col px-4">
            {!collapsed && line_items.length && productDetails}
          </div>
        </div>
      </div>
    </div>
  );
}
