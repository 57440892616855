import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { path as loginPath } from "../../../pages/login-page";
import { path as signUpPath } from "../../../pages/signup-page";
import sellImage from "../../../assets/vendi.png";
import offerImage from "../../../assets/Offerte.png";
import signUpImage from "../../../assets/Iscriviti.png";

export default function CtaSection() {
  return (
    <div className="row">
      <div className="py-5 sell-banner col">
        <div className="row">
          <div className="col layout-max-width">
            <div className="row align-items-center justify-content-center">
              <h2 className="theme-color-primary fw-600 col text-center font-24-35">
                VENDI SU FLASHWINE
              </h2>
            </div>
            <div className="row justify-content-center">
              <p className="theme-page-text fw-300 text-center col-10 col-md-5 font-14-20">
                Hai un’enoteca un ristorante o sei un commerciante? Iscriviti
                gratis e proponi le tue private flash sales!
              </p>
            </div>
            <div className="row justify-content-center mt-4 align-items-center theme-page-text">
              <div className="col-12 col-md-2 text-md-right text-center">
                <img src={signUpImage} alt="Iscriviti gratis" height="103" />
              </div>
              <div className="row mt-1 d-md-none">
                <p>Iscriviti gratis</p>
              </div>
              <div className="col-2 dashed-border d-md-block d-none"></div>
              <div className="col-12 col-md-1 text-center mt-md-0 mt-4">
                <img
                  src={offerImage}
                  alt="Aggiungi offerte flash"
                  height="103"
                />
              </div>
              <div className="row mt-1 d-md-none">
                <p>Aggiungi offerte flash</p>
              </div>
              <div className="col-2 dashed-border d-md-block d-none"></div>
              <div className="col-12 col-md-2 text-md-left text-center mt-md-0 mt-4">
                <img src={sellImage} alt="Inizia a vendere" height="103" />
              </div>
              <div className="row mt-1 d-md-none">
                <p>Inizia a vendere</p>
              </div>
            </div>

            <div className="row justify-content-center mt-4 mb-4 align-items-center d-none d-md-flex">
              <div className="col-12 col-md-2 text-right">
                <p className="theme-page-text mb-0">Iscriviti gratis</p>
              </div>
              <div className="col-1 d-md-block d-none"></div>
              <div className="col-3 text-center">
                <p className="theme-page-text mb-0 px-0">
                  Aggiungi offerte flash
                </p>
              </div>
              <div className="col-1 d-md-block d-none"></div>
              <div className="col-2 text-left">
                <p className="theme-page-text mb-0">Inizia a vendere</p>
              </div>
            </div>

            <div className="row justify-content-center mt-5">
              <Link to={signUpPath}>
                <Button
                  className="home-cta-button"
                  size="large"
                  color="button"
                  variant="contained"
                >
                  Iscriviti
                </Button>
              </Link>
            </div>
            <div className="d-flex justify-content-center mb-4 mt-3 w-100">
              <sub>
                Scopri di più,{" "}
                <u>
                  <Link className="colored-link text-gray-3" to={loginPath}>
                    clicca qui
                  </Link>
                </u>
              </sub>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
