import CollapsibleMenu from "./collapsible-menu";
import { useMediaQuery } from "react-responsive";
import { path as landingPath } from "../../pages/landing-page";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import DynamicLink from "./dynamic-link";
import { Link } from "react-router-dom";
import Config from "../../config.json";

export default function Footer({ menu }) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Groups the menu entries by title
  const hierarchicalMenu = {};
  // Double loop since there's no guarantee on the elements order
  menu
    .filter(({ menu_item_parent }) => menu_item_parent === "0")
    .forEach(
      ({ ID, title }) => (hierarchicalMenu[ID] = { title, entries: [] })
    );
  menu
    .filter(({ menu_item_parent }) => menu_item_parent !== "0")
    .forEach(({ menu_item_parent, url, title }) =>
      hierarchicalMenu[menu_item_parent].entries.push({ title, url })
    );

  let menuHtml;

  if (isMobile) {
    menuHtml = Object.entries(hierarchicalMenu).map(
      ([id, { title, entries }], i) => (
        <CollapsibleMenu title={title} entries={entries} key={i} />
      )
    );
  } else {
    menuHtml = Object.entries(hierarchicalMenu).map(
      ([id, { title, entries }], i) => (
        <div className="pt-4 col-md-2" key={i}>
          <div className="row">
            <h5 className="col font-16 fw-400 theme-footer-text">{title}</h5>
          </div>
          {entries.map((e, i) => (
            <div className="row" key={i}>
              <DynamicLink
                className="colored-link theme-footer-text font-14 fw-300 col"
                {...e}
              >
                {e.title}
              </DynamicLink>
            </div>
          ))}
        </div>
      )
    );
  }

  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <hr className="col" />
        </div>
        <div id="footer" className="theme-footer row">
          <div className="col layout-max-width">
            <div className="row">
              <div className="col-md-4 col-12 offset-md-1">
                <div className="row justify-content-md-start justify-content-center pt-3">
                  <div className="col text-center text-md-left">
                    <Link to={landingPath}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          Config.themes[Config.activeTheme].logo
                        }
                        alt="logo"
                        className="theme-footer-logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="row justify-content-md-start justify-content-center mb-5 mt-2">
                  <div className="col text-center text-md-left">
                    <a
                      href={Config.facebookUrl}
                      className="theme-footer-mobile-header link-unstyled mr-2"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href={Config.instagramUrl}
                      className="theme-footer-mobile-header link-unstyled mx-2"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href={Config.linkedinUrl}
                      className="theme-footer-mobile-header link-unstyled ml-2"
                    >
                      <LinkedInIcon />
                    </a>
                  </div>
                </div>
              </div>
              {menuHtml}
            </div>
          </div>
        </div>
        <div className="row">
          <hr className="col theme-header-footer-hr" />
        </div>
        <div
          id="bottom-footer"
          className="row align-items-center justify-content-center py-2 theme-bottom-footer"
        >
          <p className="fw-300 font-12-14 text-center mb-0 col layout-max-width theme-footer-text">
            Via Panfilo Castaldi 24 • 20139 • Milano • © FlashWine Srl 2021 •
            P.iva 08844370968
          </p>
        </div>
      </div>
    </div>
  );
}
