export async function upgradeToVendor(
  vendor_name,
  vendor_email,
  vendor_company,
  vendor_vat,
  vendor_cap,
  vendor_address,
  vendor_city,
  vendor_prov,
  vendor_rea,
  vendor_sdi,
  vendor_pec
) {
  return await fetch(`/apticashop/v1/upgrade_to_vendor`, {
    method: "POST",
    body: JSON.stringify({
      vendor_name,
      vendor_email,
      vendor_company,
      vendor_vat,
      vendor_cap,
      vendor_address,
      vendor_city,
      vendor_prov,
      vendor_rea,
      vendor_sdi,
      vendor_pec,
    }),
  });
}
