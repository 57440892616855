export async function addProductToCart(id) {
  return await fetch("/cocart/v2/cart/add-item", {
    method: "POST",
    body: JSON.stringify({ id }),
  });
}

export async function removeProductFromCart(item_key) {
  return await fetch("/cocart/v2/cart/item/" + item_key, {
    method: "DELETE",
  });
}

export async function getCart() {
  return await fetch("/cocart/v2/cart");
}

export async function clearCart() {
  return await fetch("/cocart/v2/cart/clear", {
    method: "POST",
  });
}

export async function updateItemQuantity(item_key, quantity) {
  return await fetch("/cocart/v2/cart/item/" + item_key, {
    method: "POST",
    body: JSON.stringify({ item_key, quantity, return_cart: true }),
  });
}

export async function countCartItems() {
  return await fetch("/cocart/v2/cart/items/count");
}
